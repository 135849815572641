<script>
import FaqComp from '~/components/faq/faqComp.vue';
import CheckoutComp from "~/components/checkout/checkoutComp.vue";
import GuaranteesComp from "~/components/guarantees/guaranteesComp.vue";
import HoworksComp from "~/components/howorks/howorksComp.vue";
import MobileComp from "~/components/mobile/mobileComp.vue";
import ObligationsComp from "~/components/obligations/obligationsComp.vue";
import SignInPopup from "~/components/account/account.vue";

export default {
  components: {SignInPopup, FaqComp, ObligationsComp, MobileComp, HoworksComp, GuaranteesComp, CheckoutComp},
  data () {
    return {
      openUserPopup: false
    }
  },
  head() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Какие налоги можно оплатить в сервисе?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Большая часть из перечня всех налогов, сборов и взносов платятся Единым налоговым платежом (ЕНП). Среди них: Имущественный налог — на всю недвижимость (квартиры, дома); Транспортный налог — для владельцев машин, мотоциклов, снегоходов, самолетов, яхт и других транспортных средств; Земельный — налог на право пользования землей; НДФЛ (подоходный налог) — с зарплаты, продажи имущества, дохода от инвестиций и так далее. Также в сервисе отображаются пени, начисляемые налоговой за несвоевременную оплату. Полный список налогов, входящих в ЕНП, можно посмотреть на сайте Федеральной налоговой службы."
              }
            
            },{
              "@type": "Question",
              "name": "Что такое Единый налоговые счет (ЕНС)?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Единый налоговый счет — это инновационный механизм для учета налоговых обязательств и взносов с 1 января 2023 года. Суть его заключается в том, что налогоплательщик переводит деньги на специальный счет, а налоговая служба автоматически зачисляет их в качестве платежа. ЕНС не требуется открывать специально, налоговая сама уже сделала это для всех компаний, ИП и физических лиц. От граждан требуется только своевременно его пополнять"
              }
            },{
              "@type": "Question",
              "name": "Как налоговая распределит средства на счете для погашения?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Все денежные поступления разделяются пропорционально. Инспекция будет засчитывать их в счет уплаты налогов по очереди: налоговая задолженность — от ранних к последним; текущие налоги, авансовые платежи, страховые взносы, сборы — по мере; возникновения обязанности по их уплате; пени; проценты; штрафы."
              }
            },{
              "@type": "Question",
              "name": "Правда, что можно оплатить налоги за другого человека?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Да, с 2017 года за другого человека можно заплатить любые налоги, а также штрафы и начисленные по ним пени, так же, как за себя. При этом родственные или гражданско-правовые отношения важны и подтверждать их не нужно"
              }
            },{
              "@type": "Question",
              "name": "Как заплатить долги по налогам?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "1) Укажите ИНН, паспортные данные или номер квитанции (УИН) 2) Оплатите задолженность ЕНС банковской картой, через СБП или QR-кодом. Деньги уйдут в Федеральное казначейство на счет налоговой 3) Получите квитанцию на адрес электронной почты сразу после оплаты 4) Налог погашен, как только Федеральное казначейство соотнесет платеж с налогом и сообщит об этом в ФНС"
              }
            },{
              "@type": "Question",
              "name": "Почему я вижу уже оплаченные налоги?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Так бывает, если Федеральная налоговая служба еще не успела зачесть платеж. Федеральное казначейство соотносит полученные данные и передает их в ФНС в течение 10 рабочих дней. Если после оплаты через «Оплатить налог» прошло больше двух недель, а налоговая задолженность не была аннулирована в базе, напишите нам: support@оплатить-налог.рф"
              }
            },{
              "@type": "Question",
              "name": "Чем отличается проверка налогов по ИНН и по УИН?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Используя ИНН, можно узнать информацию о любых налоговых задолженностях и пенях. По УИН же вы получите информацию о текущих начислениях. Эта особенность связана с функционированием ГИС ГМП и касается вопросов налогообложения для физических лиц"
              }
            }]
          }
        }
      ],
    }
  },
}


</script>

<template>
  <div>
    <CheckoutComp />
    <GuaranteesComp />
    <HoworksComp />
    <MobileComp />
    <ObligationsComp />

    <FaqComp />

  </div>
</template>
