<template>
  <section class="faq center">
    <div class="faq_container">
      <h2 class="faq_container__title">
        Ответы на частые вопросы
      </h2>

      <div itemscope itemtype="http://schema.org/FAQPage" class="faq_container_box">
        <div class="faq_container_box_column">
          <FaqQuestion class="question" :checkbox-id="'1'" text=" Какие налоги можно оплатить в сервисе?">

            <template>
              Большая часть из перечня всех налогов, сборов и взносов платятся Единым налоговым платежом (ЕНП). Среди них:<br>
              <b>Имущественный налог</b> — на всю недвижимость (квартиры, дома);<br>
              <b>Транспортный налог</b> — для владельцев машин, мотоциклов, снегоходов, самолетов, яхт и других транспортных средств;
              <b>Земельный</b> — налог на право пользования землей;<br>
              <b>НДФЛ (подоходный налог)</b> — с зарплаты, продажи имущества, дохода от инвестиций и так далее.<br>
              Также в сервисе отображаются пени, начисляемые налоговой за несвоевременную оплату.
              Полный список налогов, входящих в ЕНП, можно посмотреть на сайте
              <a href="https://www.nalog.gov.ru/rn19/taxation/submission_statements/rekvizit/13002330/?ysclid=lwov94gnda902506937">
                Федеральной налоговой службы.
              </a>
            </template>
          </FaqQuestion>

          <FaqQuestion class="question" :checkbox-id="'3'" text="Что такое Единый налоговые счет (ЕНС)?">

            <template>
              <b>Единый налоговый счет</b> — это инновационный механизм для учета налоговых обязательств и взносов с
              1 января 2023 года. Суть его заключается в том, что налогоплательщик переводит деньги на специальный
              счет, а налоговая служба автоматически зачисляет их в качестве платежа. ЕНС не требуется открывать
              специально, налоговая сама уже сделала это для всех компаний, ИП и физических лиц. От граждан
              требуется только своевременно его пополнять
            </template>
          </FaqQuestion>

          <FaqQuestion class="question" :checkbox-id="'5'" text="Как налоговая распределит средства на счете для погашения?">

            <template>
              Все денежные поступления разделяются пропорционально. <br>
              Инспекция будет засчитывать их в счет уплаты налогов по очереди:<br>
              <ul>
                <li>налоговая задолженность — от ранних к последним;</li>
                <li>текущие налоги, авансовые платежи, страховые взносы, сборы — по мере;</li>
                <li>возникновения обязанности по их уплате;</li>
                <li>пени;</li>
                <li>проценты;</li>
                <li>штрафы.</li>
              </ul>
            </template>
          </FaqQuestion>

          <FaqQuestion class="question" :checkbox-id="'7'" text="Правда, что можно оплатить налоги за другого человека?">

            <template>
              <b>Да</b>, с 2017 года за другого человека можно заплатить любые налоги,
              а также штрафы и начисленные по ним пени, так же, как за себя.
              При этом родственные или гражданско-правовые отношения не важны и подтверждать их не нужно
            </template>
          </FaqQuestion>
        </div>

        <div class="faq_container_box_column">
          <FaqQuestion class="question" :checkbox-id="'2'" text="Как заплатить долги по налогам?">

            <template>
              <ol>
                <li>Укажите ИНН, паспортные данные или номер квитанции (УИН)</li>
                <li>Оплатите задолженность ЕНС банковской картой, через СБП или QR-кодом. Деньги уйдут в Федеральное казначейство на счет налоговой</li>
                <li>Получите квитанцию на адрес электронной почты сразу после оплаты</li>
                <li>Налог погашен, как только Федеральное казначейство соотнесет платеж с налогом и сообщит об этом в ФНС</li>
              </ol>
            </template>
          </FaqQuestion>

          <FaqQuestion class="question" :checkbox-id="'4'" text="Почему я вижу уже оплаченные налоги?">

            <template>
              Так бывает, если Федеральная налоговая служба еще не успела зачесть платеж.
              Федеральное казначейство соотносит полученные данные и передает их в ФНС в течение
              10 рабочих дней. Если после оплаты через «Оплатить налог» прошло больше двух недель,
              а налоговая задолженность не была аннулирована в базе, напишите нам:
              <span><a href="mailto:support@оплатить-налог.рф">support@оплатить-налог.рф</a></span>
            </template>
          </FaqQuestion>

          <FaqQuestion class="question" :checkbox-id="'6'" text="Чем отличается проверка налогов по ИНН и по УИН?">

            <template>
              Используя ИНН, можно узнать информацию о любых налоговых задолженностях и пенях.
              По УИН же вы получите информацию о текущих начислениях. Эта особенность связана с
              функционированием ГИС ГМП и касается вопросов налогообложения для физических лиц
            </template>
          </FaqQuestion>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FaqQuestion from "~/components/faq/faqQuestion.vue";
export default {
  components: {FaqQuestion}
}
</script>

<style lang="scss" scoped>
    .faq {
        width: 100%;
        margin-bottom: 59px;

        &_container {
            display: flex;
            flex-direction: column;
            gap: 27px;

            width: 100%;
            max-width: 1296px;

            &__title {
                font-size: 30px;
                font-weight: 700;
                line-height: 33px;
                color: var(--title-text-color)
            }

            &_box {
                display: flex;
                gap: 30px;

                &_column {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    width: 100%;

                    .question {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .faq {
            width: 100%;
            margin-bottom: 21px;

            &_container {
                gap: 25px;

                width: 100%;
                max-width: 390px;

                &__title {
                    font-size: 22px;
                    line-height: 26.4px;
                }

                &_box {
                    flex-direction: column;
                    gap: 20px;

                    &_column {
                        gap: 20px;
                    }
                }
            }
        }
    }
</style>
